<template>
  <v-layout row wrap>
    <v-alert :value="!invoicingSettings" type="warning" style="width: 100%">
      Please complete the invoicing settings.
      <a :href="'#/settings'">Organization Settings</a>
    </v-alert>
    <v-alert type="error" :value="hasError" style="width: 100%">
      <div class="div-grid">
        <div>Problem Creating Link</div>
        <div v-for="(err, index) in errorMsg" v-bind:key="`error-${index}`">
          {{ err }}
        </div>
      </div>
    </v-alert>
    <v-flex class="pa-1" xs12>
      <div v-if="isLoading" class="tw-flex tw-justify-center tw-items-center tw-absolute tw-inset-0 tw-z-20">
        <ui-loading class="tw-text-8xl tw-opacity-100"></ui-loading>
      </div>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="validInvoices"
        :rows-per-page-items="[10, 50, 100, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]"
        :pagination.sync="pagination"
        :items-per-page="10"
        select-all
        item-key="title"
        :loading="isLoading ? 'accent' : false"
        class="elevation-3"
        :hide-actions="validInvoices.length <= 5"
      >
        <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable text-xs-left',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
              ]"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <v-icon small>arrow_upward</v-icon>
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr>
            <td>{{ props.item.title }}</td>
            <td class="text-xs-left" width="20px">
              <img v-if="props.item.source === 'Xero'" src="xero-small.png" style="height: 20px" />
              <img v-if="props.item.source === 'Quickbooks'" src="qb.png" style="height: 20px" />
            </td>
            <td>{{ props.item.reference }}</td>
            <td>${{ props.item.totalAmount }}</td>
            <td>
              <v-icon v-if="props.item.hasMatchedTransactions" color="green">done</v-icon>
              <v-icon v-else>close</v-icon>
            </td>
            <td>
              {{ props.item.status }}
            </td>
            <td v-if="props.item.url !== undefined && props.item.url !== null">
              <v-tooltip bottom>
                <template slot="activator">
                  <a :href="props.item.url" target="_blank">
                    <v-icon>visibility</v-icon>
                  </a>
                  &nbsp;
                </template>
                <span>View Invoice</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template slot="activator">
                  <v-icon
                    @click="
                      copyToClipboard('To pay this invoice with BTC, please click this link: \r\n' + props.item.url)
                    "
                    >file_copy</v-icon
                  >
                </template>
                <span>Copy Link</span>
              </v-tooltip>
            </td>
            <td v-else>
              <template v-if="props.item && isCreatingLink(props.item)">
                <v-progress-circular class="tw-h-[30px]" indeterminate color="primary"></v-progress-circular>
                ... creating link
              </template>
              <template v-else>
                <button
                  type="button"
                  class="tw-rounded-xs tw-cursor-pointer tw-p-2 tw-bg-success-300 active:tw-border active:tw-border-primary-300 hover:tw-bg-success-200"
                  @click="createUrlToken(props.item.id)"
                >
                  <v-icon small>link</v-icon>
                  Create Link
                </button>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';

import { ConnectionCategory } from '@/api-svc-types';

import { MUT_SNACKBAR } from '../../store';

export default {
  props: [],
  components: {},
  data() {
    return {
      isLoading: false,
      pagination: {
        sortBy: 'title',
      },
      hasError: false,
      errorMsg: '',
      selected: [],
      headers: [
        {
          text: this.$t('_title'),
          align: 'left',
          value: 'title',
        },
        {
          text: this.$t('_source'),
          align: 'left',
          value: 'source',
        },
        {
          text: this.$t('_reference'),
          align: 'left',
          value: 'reference',
        },
        {
          text: this.$t('_amount'),
          align: 'left',
          value: 'totalAmount',
        },
        {
          text: this.$t('_matched'),
          align: 'center',
          value: 'status',
        },
        {
          text: this.$t('_systemStatus'),
          align: 'left',
          value: 'status',
        },
        {
          text: this.$tc('_action', 2),
          align: 'left',
          value: 'invoiceUrl',
        },
      ],
      contacts: [],
      invoices: [],
      validInvoices: [],
      invoicesCreatingLink: [],
      connections: [],
    };
  },
  apollo: {
    invoices: {
      query: gql`
        query GetInvoices($orgId: ID!) {
          invoices(orgId: $orgId, includeDisabled: true) {
            id
            type
            status
            source
            title
            reference
            totalAmount
            status
            url
            hasMatchedTransactions
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update: (data) => {
        // Receiving = invoices | Paying = bills
        return data.invoices.filter((m) => m.type === 'Receiving');
      },
      loadingKey: 'isLoading',
    },
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            isDisabled
            isDeleted
            category
            name
            feeAccountCode
            isDefault
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      update(data) {
        return (
          data.connections?.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection) ?? []
        );
      },
    },
  },
  computed: {
    invoicingSettings() {
      const org = this.$store.state.currentOrg;

      if (
        org.invoicingSettings &&
        org.invoicingSettings.fromEmail &&
        (org.invoicingSettings.bitcoinAddress ||
          org.invoicingSettings.bitcoinXPub ||
          org.invoicingSettings.bitcoinXPubPath ||
          org.invoicingSettings.ethAddress ||
          org.invoicingSettings.usdcAddress ||
          org.invoicingSettings.usdtAddress ||
          org.invoicingSettings.avaxAddress ||
          org.invoicingSettings.alotAddress ||
          org.invoicingSettings.aleoAddress)
      ) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$apollo.queries.invoices.refetch();
    await this.$apollo.queries.connections.refetch();
    this.invoicesCreatingLink = []; // reset the list of invoices that are being created

    const disabledConnectionIds = this.connections
      .filter((c) => {
        return c.category === ConnectionCategory.AccountingConnection && c.isDisabled;
      })
      .map((c) => {
        return c.id;
      });

    this.validInvoices = this.invoices.filter((i) => {
      const connectionId = i.id.split('.')[0];
      return disabledConnectionIds.indexOf(connectionId) === -1;
    });
    this.isLoading = false;
  },
  methods: {
    copyToClipboard(text) {
      if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        /* eslint-disable */
        return clipboardData.setData('Text', text);
        /* eslint-enable */
      } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        var textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand('copy'); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    refresh() {
      this.$apollo.queries.invoices.refetch();
    },
    createUrlToken(invoiceId) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        invoiceId,
      };
      this.isLoading = true;
      this.errorMsg = '';
      this.hasError = false;
      this.invoicesCreatingLink.push(invoiceId);

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($orgId: ID!, $invoiceId: ID!) {
              createUrlTokenForInvoice(orgId: $orgId, invoiceId: $invoiceId)
            }
          `,
          variables: vars,
        })
        .then(() => {
          this.$store.commit(MUT_SNACKBAR, {
            color: 'success',
            message: 'Link created successfully',
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.errorMsg = err.graphQLErrors.map((e) => e.message);
          this.isLoading = false;
          this.hasError = true;
        })
        .finally(() => {
          this.refresh();
        });
    },
    isCreatingLink(item) {
      if (!item) {
        return false;
      }

      return this.invoicesCreatingLink.length > 0 && this.invoicesCreatingLink.includes(item?.id);
    },
  },
};
</script>
